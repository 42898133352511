// @ts-nocheck
'use strict';

var Swo = require('swiper/bundle');
var prodSwiper;

module.exports = {
    mySwiper: function () {
        prodSwiper = new Swo.Swiper('.product-list-carousel', {
            direction: 'horizontal',
            slidesPerView: 3,
            spaceBetween: 24,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            freeMode: true,
            preloadImages: false,
            watchSlidesVisibility: false,
            breakpoints: {
                768: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 4
                },
                1280: {
                    slidesPerView: 5
                }
            }
        });
    },
    getProdSwiper: function () {
        return prodSwiper;
    }
};
